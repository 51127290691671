@font-face {
    font-family: 'Montserrat';
    src: url(fonts/Montserrat-ExtraLightItalic.8cdb23a.woff2) format('woff2'),
        url(fonts/Montserrat-ExtraLightItalic.2f3578b.woff) format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url(fonts/Raleway-Black.6f505fc.woff2) format('woff2'),
        url(fonts/Raleway-Black.65f6036.woff) format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url(fonts/Raleway-Light.cefd3f3.woff2) format('woff2'),
        url(fonts/Raleway-Light.0e89a10.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(fonts/Montserrat-Thin.ed5ced2.woff2) format('woff2'),
        url(fonts/Montserrat-Thin.152b34f.woff) format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url(fonts/Raleway-SemiBoldItalic.7b8ae5e.woff2) format('woff2'),
        url(fonts/Raleway-SemiBoldItalic.c4d159a.woff) format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(fonts/Montserrat-Italic.82168e0.woff2) format('woff2'),
        url(fonts/Montserrat-Italic.dcda6be.woff) format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url(fonts/Raleway-LightItalic.58ea362.woff2) format('woff2'),
        url(fonts/Raleway-LightItalic.45ee41f.woff) format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(fonts/Montserrat-SemiBoldItalic.5fec409.woff2) format('woff2'),
        url(fonts/Montserrat-SemiBoldItalic.7b1d233.woff) format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url(fonts/Raleway-Bold.8b61c60.woff2) format('woff2'),
        url(fonts/Raleway-Bold.22dc6db.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url(fonts/Raleway-Regular.8447ab6.woff2) format('woff2'),
        url(fonts/Raleway-Regular.4f0b9ca.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(fonts/Montserrat-MediumItalic.43a841b.woff2) format('woff2'),
        url(fonts/Montserrat-MediumItalic.5eb647b.woff) format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(fonts/Montserrat-Black.0b4a7a7.woff2) format('woff2'),
        url(fonts/Montserrat-Black.2ed2c79.woff) format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url(fonts/Raleway-ThinItalic.35d68fe.woff2) format('woff2'),
        url(fonts/Raleway-ThinItalic.0581053.woff) format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url(fonts/Raleway-BoldItalic.d0289d0.woff2) format('woff2'),
        url(fonts/Raleway-BoldItalic.4a32c2b.woff) format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url(fonts/Raleway-Thin.2697366.woff2) format('woff2'),
        url(fonts/Raleway-Thin.fd2f806.woff) format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url(fonts/Raleway-ExtraLightItalic.aaff71c.woff2) format('woff2'),
        url(fonts/Raleway-ExtraLightItalic.6b97f64.woff) format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(fonts/Montserrat-ThinItalic.71e915f.woff2) format('woff2'),
        url(fonts/Montserrat-ThinItalic.dbcc614.woff) format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(fonts/Montserrat-ExtraBold.6954210.woff2) format('woff2'),
        url(fonts/Montserrat-ExtraBold.6bf1740.woff) format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(fonts/Montserrat-Medium.4193cb3.woff2) format('woff2'),
        url(fonts/Montserrat-Medium.72aebf4.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url(fonts/Raleway-SemiBold.6e97792.woff2) format('woff2'),
        url(fonts/Raleway-SemiBold.47b88fa.woff) format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url(fonts/Raleway-Medium.25fa062.woff2) format('woff2'),
        url(fonts/Raleway-Medium.9900426.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url(fonts/Raleway-ExtraBoldItalic.5e4ec2d.woff2) format('woff2'),
        url(fonts/Raleway-ExtraBoldItalic.ce3718c.woff) format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(fonts/Montserrat-LightItalic.8bd27fe.woff2) format('woff2'),
        url(fonts/Montserrat-LightItalic.7267159.woff) format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(fonts/Montserrat-Light.40e3170.woff2) format('woff2'),
        url(fonts/Montserrat-Light.75ac2d4.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(fonts/Montserrat-BoldItalic.bcc7a47.woff2) format('woff2'),
        url(fonts/Montserrat-BoldItalic.660f8f3.woff) format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(fonts/Montserrat-Bold.1eaf595.woff2) format('woff2'),
        url(fonts/Montserrat-Bold.137a9f5.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url(fonts/Raleway-Italic.7837c6d.woff2) format('woff2'),
        url(fonts/Raleway-Italic.8e962dd.woff) format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(fonts/Montserrat-ExtraLight.99b7ee2.woff2) format('woff2'),
        url(fonts/Montserrat-ExtraLight.08cb6a5.woff) format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(fonts/Montserrat-Regular.6c88072.woff2) format('woff2'),
        url(fonts/Montserrat-Regular.c0b8804.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(fonts/Montserrat-ExtraBoldItalic.df71456.woff2) format('woff2'),
        url(fonts/Montserrat-ExtraBoldItalic.30f0e9b.woff) format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url(fonts/Raleway-BlackItalic.7514fb9.woff2) format('woff2'),
        url(fonts/Raleway-BlackItalic.57d86bd.woff) format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(fonts/Montserrat-SemiBold.c74260c.woff2) format('woff2'),
        url(fonts/Montserrat-SemiBold.f410366.woff) format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(fonts/Montserrat-BlackItalic.cec2d49.woff2) format('woff2'),
        url(fonts/Montserrat-BlackItalic.68f02d7.woff) format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url(fonts/Raleway-ExtraBold.ea462ff.woff2) format('woff2'),
        url(fonts/Raleway-ExtraBold.b710a27.woff) format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url(fonts/Raleway-ExtraLight.4cb4954.woff2) format('woff2'),
        url(fonts/Raleway-ExtraLight.8618633.woff) format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url(fonts/Raleway-MediumItalic.c95275b.woff2) format('woff2'),
        url(fonts/Raleway-MediumItalic.5d56c8a.woff) format('woff');
    font-weight: 500;
    font-style: italic;
}

